import React from "react"
import SEO from "../../components/SEO"
import Footer from "../../components/footer"
import { ProductNav } from "../../components/header/Header"
import { graphql, navigate } from "gatsby"
import {
  ProductSupport,
  SolutionTopBanner,
  ProductBlog,
  AnimationWrap,
} from "../../components/block"
import { Action } from "./edge-data-center-services"
import { Solution, Feedback, MapContainer } from "./bare-metal-cloud"
import "../../styles/ip-transit.less"
const staticPath = "/products/ipt"
// const navigate = url => window.open(url)
const loadText = require("src/utils").loadText
const url_data = loadText("url")
const static_data = loadText("ip-transit")
const B2 = () => (
  <div className="ipTransit-b2">
    <div className="ipTransit-b2-bg full-bg">
      <AnimationWrap>
        <div className="page-middle">
          <div className="imgContainer">
            <img src="/products/ipt/b2-pic.jpg" alt={static_data.b2.title} />
          </div>
          <div className="textContainer">
            <div className="font-36 semi-bold t-color">
              {static_data.b2.title}
            </div>
            <div className="font-20 mt-24 block-middle">
              {static_data.b2.description}
            </div>
          </div>
        </div>
      </AnimationWrap>
    </div>
  </div>
)
const B1 = () => (
  <div className="ipTransit-b1 ">
    <AnimationWrap>
      <div className="ipTransit-b1-content full-bg mobile-padding">
        <div className="font-36 semi-bold t-color">{static_data.b1.title}</div>
        <div
          className="font-20 mt-24 block-middle"
          style={{ maxWidth: "1016px" }}
        >
          {static_data.b1.description}
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const B3 = () => (
  <div className="ipTransit-b3">
    <div className="ipTransit-b3-content full-bg">
      <AnimationWrap>
        <div className="font-36 semi-bold">{static_data.b3.title}</div>
        <div className="font-20 mt-24">{static_data.b3.description}</div>
      </AnimationWrap>
    </div>
  </div>
)
export default function Home({ data }) {
  const { allStrapiPosts, strapiTestimonials } = data
  return (
    <div className="ipTransit">
      <SEO {...static_data.seo} featuredImage="/featuredImage/ipt.jpg" />
      <SolutionTopBanner
        {...static_data.topBanner}
        backgroundAnimated={true}
        iconBg={`${staticPath}/iconBg.svg`}
        animatedIcon={`${staticPath}/animatedIcon.svg`}
        bgPic={`${staticPath}/topBanner-bg.svg`}
        bgColor="linear-gradient(180deg,rgba(246,254,254,1) 0%,rgba(222,246,245,1) 100%)"
        bannerPic={{
          src: `${staticPath}/topBanner-m-bg.svg`,
          style: {
            backgroundPosition: "top center",
          },
        }}
        source="ipt"
      />
      {/* <ProductNav
        name={static_data.ipTransit}
        menus={static_data.navigation}
        minWidth={1100}
      />
      <a id="overview" />
      <B1 />
      <B2 />
      <div style={{ backgroundColor: "rgb(245,254,254)" }}>
        <B3 />
      </div>
      <ProductSupport
        bgColor="linear-gradient(180deg,rgba(246,254,254,1) 0%,rgba(222,246,245,1) 100%)"
        bgPic={`${staticPath}/surport-bg.svg`}
        woman={`${staticPath}/surport-woman.png`}
        source="ipt"
      />
      <a id="locations" />
      <div className="mt-64">
        <MapContainer
          title={static_data.map.title}
          description={static_data.map.description}
          defaultSelected={[url_data.ipt.name]}
        />
      </div>
      <a id="testimonials" />
      <Feedback {...strapiTestimonials} />
      <Action
        pic={`/products/ipt/action.png`}
        title={static_data.action.title}
        description={static_data.action.description}
        buttonText={static_data.action.buttonText}
        onClick={() => {
          navigate("/customer-story/zego")
        }}
      />
      <Solution
        title={static_data.solution.title}
        description={static_data.solution.description}
      />
      <a id="learnMore" />
      <ProductBlog
        title={static_data.blog.title}
        blogs={allStrapiPosts.edges.map(({ node }) => node)}
      /> */}
      <Footer />
    </div>
  )
}
export const query = graphql`
  {
    allStrapiPosts(
      filter: {
        categories: { elemMatch: { name: { eq: "IP Transit" } } }
        author: {}
      }
    ) {
      edges {
        node {
          slug
          thumbnail {
            localFile {
              publicURL
            }
          }
          wp_date
          created_at
          content
          title
          categories {
            name
          }
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
    strapiTestimonials(inPage: { eq: "ipt" }) {
      customerTitle
      customerName
      content
      companyPreview {
        localFile {
          publicURL
        }
      }
      logo {
        localFile {
          publicURL
        }
      }
      companyName
    }
  }
`
